import React, { useState, useRef } from 'react';
import axios from 'axios';
import { 
  Button, 
  Spin, 
  Progress, 
  Modal,
} from 'antd';
import { VideoCameraFilled, PauseOutlined, RedoOutlined, PlayCircleOutlined, UploadOutlined } from '@ant-design/icons';
import environment from '../../environments';
import './styles.scss';
import VideoRecorder from './videorecorder';

const S3URL = environment.s3Url;

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

const permissionDenied = () => {
  Modal.error({
    centered: true,
    title: 'Permiso denegado',
    content: 'Para continuar con la video entrevista debes ir a la configuración de tu navegador, conceder el acceso y actualizar la página',
    zIndex: 9999,
  });
}

const Recorder = (props) => {
    const [message, setMessage] = useState('...');
    const [recording, setRecording] = useState(false);
    const [trialsRemaining, setTrialsRemaining] = useState(props.trials); // Vidoerecording tries
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [countdownFinished, setCountdownFinished] = useState(false);
    const [recorderReady, setRecorderReady] = useState(false);
    const [file, setFile] = useState({
      url: null,
      blob: null,
      mimeType: null,
    });
    const recorder = useRef({
      setUpVideo: () => {},
      startRecording: () => {},
      stopRecording: () => {},
      download: () => {},
      playRecordedVideo: () => {},
      playVideo: () => {},
    });

    const onCountdown = (time) => {
      if (time <= 0) {
        setCountdownFinished(true);
      }
    }

    const onRecordingProgress = (time) => {
      const userTime = time;
      setProgress(userTime);
      if (userTime >= props.time) {
        setRecording(false);
      }
    }

    const handleOnRecording = () => {
      setRecording(true);
    };

    const handleRecorderReady = (r) => {
      setRecorderReady(r);
    }

    const onFinishRecording = (url, blob, mimeType) => {
      setRecording(false);
      setFile({
        url,
        blob,
        mimeType,
      });
    }
 
    const handleUpload = async () => {
      setMessage("Cargando...")
      setLoading(true)
      const fileBase64 = await blobToBase64(file.blob);
      const body = {
        questionId: props.questionId,
        interviewId: props.interviewId,
        fileName: file.url.split('/').pop(),
        mimeType: file.blob.type,
        candidateId: props.candidateId,
      }
      // Ask for presigned upload url from lambda
      // that verifies authentication and that there
      // is no answer to question.
      const lambdaEndpoint = environment.lambdaUrl;
      try{
        const response = await axios.post(
          lambdaEndpoint, 
          body,
          { headers: { 'Content-Type': 'application/json' } }
        )
        const videoUploadS3Url = response.data.result;
        // Upload using presigned s3 url
        const responseS3 = await axios.put(
          videoUploadS3Url,
          file.blob,
          { headers: { 'Content-Type': file.blob.type } }
        )
        const videoUrl = videoUploadS3Url.split('?')[0];
        const videoData = await props.uploadVideo({ url: videoUrl })
        await props.handleVideoRecorded(videoData.data.id);
        setLoading(false);
      } catch(e) {
        setLoading(false);
        setMessage(null);
        Modal.error({
          centered: true,
          title: 'Hubo un problema con la subida.',
          content: 'Por favor revisa tu conexión a internet y vuelve a intentarlo con el botón "Subir".',
          zIndex: 9999,
        });
      } 
    }

    const handleRecordStop = () => {
      if (recording) {
        recorder.current.stopRecording();
        setRecording(false);
      } else {
        setProgress(0);
        setRecording(true);
        // recorder.current.startRecording();
        recorder.current.startRecordingWithCountdown(3);
      }
    };

    const handleReRecord = () => {
      if (trialsRemaining > 0) {
        setFile({
          url: null,
          blob: null,
          mimeType: null,
        });
        recorder.current.reset();
        setRecording(false);
        setProgress(0);
        // Podria ser mejor algo como un blob guardado en el state
        // y que se borre cuando se sube el video
        setCountdownFinished(false);

        if (trialsRemaining === 1 && !props.demo) {
          Modal.info({
            centered: true,
            title: 'Último intento.',
            content: 'Esta es tu última oportunidad para grabar. Al finalizar deberás enviar tu respuesta para continuar.',
            zIndex: 9999,
          });
        }
        setTrialsRemaining(t => t - 1);
      }
    }

    const onPermissionSuccess = () => {
      recorder.current.setUpVideo();
    }

    const onPermissionError = () => {
      Modal.error({
        centered: true,
        title: 'Hubo un problema con tu cámara y micrófono.',
        content: 'Para continuar con la video entrevista debes ir a la configuración de tu navegador, conceder el acceso y actualizar la página.',
        zIndex: 9999,
      });
    }

    let date = new Date(0);
    date.setSeconds(props.time - progress);

    return (
      <div>
        <div className='vi-candidate-progress'>
          <Progress className='vi-candidate-progress__progress' percent={progress * 100 / props.time} />
          <span className='vi-candidate-progress__seconds'>{`${date.getMinutes()}:${('0' + date.getSeconds()).slice(-2)}`}</span>
        </div>

        <Spin tip="Subiendo video..." size="large" spinning={loading}>
          <VideoRecorder 
            ref={recorder}
            demo={props.demo}
            time={props.time < 301 ? props.time : 10}
            onFinishRecording={onFinishRecording}
            onRecording={handleOnRecording}
            onCountdown={onCountdown}
            onRecordingProgress={onRecordingProgress}
            onRecorderReady={handleRecorderReady}
            onPermissionSuccess={onPermissionSuccess}
            onPermissionError={onPermissionError}
          />
        </Spin>

        <div className='record-btn'>
          <>
          {recorderReady && !recording && !countdownFinished ? <Button type="danger" onClick={handleRecordStop}><VideoCameraFilled />{props.demo ? 'Probar' : 'Grabar'}</Button> : null}
          {!recording && countdownFinished && !props.demo && loading ? <Button type="danger" disabled={loading} loading={loading}>{message}</Button> : null}
          </>
          {countdownFinished &&
            <>
              {recording && <Button type="default" onClick={handleRecordStop}><PauseOutlined /> Detener</Button>}
              {!recording && trialsRemaining > 0 && !loading ? <Button type="default" onClick={handleReRecord}><RedoOutlined /> Reintentar</Button> : null}
              {!recording && !props.demo && !loading ? <Button type="default" onClick={recorder.current.playRecordedVideo}><PlayCircleOutlined /> Previsualizar</Button> : null}
              {!recording && !props.demo && !loading ? <Button type="default" onClick={handleUpload}><UploadOutlined /> Enviar</Button> : null}
            </>
          }
          {}
        </div>
      </div>
    );
}
 
export default Recorder;

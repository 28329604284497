import React, { Component } from 'react';
import { Progress, Card, Button, Modal } from 'antd';

import { getInterviewQuestions,
  validateCandidateInterview } from '../../services/interviews';
import RecorderTest from '../../components/video-test';
import monoGenoma from '../../assets/mono-genoma.png'
import checkImage from '../../assets/check.png'
import './styles.scss'

export default class ProcessDetail extends Component {
  state = { 
    loading: false,
    currentQuestion: 0,
    questions: [],
    candidateName: "El curis",
    modalVisible: false
  }

  componentWillMount() {
    this.setState({ loading: true })
  }

  async componentDidMount() {
    this.setState({ loading: true })
    try {
      this.loading = true;
      const id = this.props.match.params["id"]
      const aux = await validateCandidateInterview(id);
      // Guardar toda la información de la entrevista que se está consultando y
      // del candidato que va a responder la entrevista
      this.setState({
        processId: id,
        interviewId: aux.data.interview.id,
        interviewInfo: aux.data.interview
      })
      const { candidate, interview, expired } = aux.data;
      this.setState({ expired });
      if (!interview || expired) { throw new Error('No hay registro de entrevista o la videoentrevista expiró'); }
      this.setState({ interview });
      // Sacar la información del candidato y enviarlo al momento de consultar por
      // las preguntas de la entrevista (para sacar cuáles son las que respondió
      // el usuario actual y partir desde ahí).
      // Por ahora se está enviando así no más el id del usuario, pero a futuro
      // debiese haber alguna validación o mejorar la forma de sacar la info
      const candidateId = candidate.id;
      const candidateMail = candidate.mail;
      const candidateName = candidate.name;
      this.setState({
        candidateId, candidateMail, candidateName
      })
      const questions = (await getInterviewQuestions(interview.id, candidateId)).data
      this.setState({ questions });
      // // Recorrer el arreglo con todas las preguntas del proceso e ir viendo
      // // hasta cuál no respondió el usuario. En caso que venga alguna respuesta
      // // del usuario
      let currentQuestion = 0;
      for (const question of questions) {
        if (question.answers.length) { currentQuestion += 1; }
      }
      this.setState({ currentQuestion });

    } catch (err) {
      // this.router.navigate(['']);
      console.error('Ocurrió un error', err);

    } finally {
      this.setState({ loading: false });
    }
  }

  handleOpenModal = () => {
    const { modalVisible } = this.state;
    this.setState({ modalVisible: !modalVisible })
  }

  handleModalOk = () => {
    const id = this.props.match.params["id"]
    window.location = `/processes/${id}/interview`
  }

  render() {
    const { loading, questions, currentQuestion, candidateName, expired } = this.state;
    return ( 
    <div className='pd'>
      {loading ? 
        <Progress percent={100} status="active" showInfo={false} />
      : null }
      
      <Modal
        className='test'
        visible={this.state.modalVisible}
        onOk={this.handleModalOk}
        onCancel={this.handleOpenModal}
        centered={true}
        footer={[
          <Button key="back" onClick={this.handleOpenModal}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" onClick={this.handleModalOk}>
            Comenzar
          </Button>,
        ]}
      >
        <RecorderTest />
      </Modal>

      {!loading && questions.length > currentQuestion ? 
        <div className="pd">
          <Card className='card home' title={
            <div>
              <img src={monoGenoma} width="120" alt='' />
              <h1>Te damos la bienvenida { candidateName }</h1>
            </div>
          }>
            <div className='card-content'>
              <p className="welcome-text">
                Has avanzado a la siguiente etapa y en esta instancia tendrás que responder unas preguntas que 
                la empresa ha preparado para conocerte mejor. <br />
                Tu video solo podrá ser visualizado por la empresa a la que estas postulando.
              </p>
              <div className="int-button">
                <Button onClick={this.handleOpenModal}> Comenzar video entrevista</Button>
              </div>
              <div className="int-tips">
                <div className="wrap">
                  <h2>¿Qué esperar?</h2>
                  <div className="pd__list">
                    <div className="pd__list__item">Cada pregunta tiene un máximo de segundos para responder.</div>
                    <div className="pd__list__item">Tendrás solo 1 intento por pregunta.</div>
                    <div className="pd__list__item">Podrás leer la pregunta antes de grabar tu respuesta.</div>
                  </div>
                </div>
                <div className="wrap second">
                  <h2>Algunos tips para la entrevista</h2>
                  <div className="pd__list">
                    <div className="pd__list__item">Utiliza el navegador Google Chrome actualizado.</div>
                    <div className="pd__list__item">Asegúrate de tener una buena y estable conexión a internet.</div>
                    <div className="pd__list__item">Graba tu video entrevista en un ambiente iluminado, silencioso y donde no puedas ser interrumpido.</div>
                    <div className="pd__list__item">Al contestar mira directamente a la cámara y habla tranquilamente.</div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      : null}
      
      {!loading && questions?.length > 0 && questions?.length === currentQuestion ? 
        <div className="interview-finished">
          <Card className='card' title={
            <div>
              <img width='60' src={checkImage} alt="Genoma - Video entrevista finalizada" />
              <h1>Entrevista finalizada</h1>
              <p>¡Has finalizado exitosamente la video entrevista!</p>
            </div>
          }>
            <p>La empresa a la cual postulaste te contactará dentro de las próximas semanas
              para manternerte en línea con los avances del proceso.</p>
          </Card>
      </div>
        : null}

      {/* ID Inválido */}
      {!loading && !expired && questions?.length === 0 ? 
        <div className="unknown-interview">
            <Card className='card' title={
              <div>
                <img src={monoGenoma} alt='genomin' />
                <h1>Video entrevista no existe</h1>
              </div>
            }>
              <p>Si llegaste aquí y crees que es un error, por favor contacta a soporte.</p>
            </Card>
        </div>
          : null}
      {/* VINT expirada */}
      {!loading && expired ? 
        <div className="unknown-interview">
            <Card className='card' title={
              <div>
                <img src={monoGenoma} alt='genomin' />
                <h1>Videoentrevista expirada</h1>
              </div>
            }>
              <p>El proceso ya no está aceptando más videoentrevistas. Gracias por participar.</p>
            </Card>
        </div>
          : null}
    </div>
      );
  }
}

import React, { useEffect, useState, useRef, useMemo, useCallback, forwardRef } from "react";
import {
  isIOS,
  isSafari
} from "react-device-detect";

import {
  Button,
} from 'antd';

const oppositeMimeTypes = {
  'video/webm': 'video/mp4',
  'video/mp4': 'video/webm'
}

function VideoRecorder({
  // props
  time,
  showButtons=false,
  demo=false,
  // Callbacks
  onRecording,
  onFinishRecording,
  onCountdown,
  onRecordingProgress,
  onRecorderReady,
  onPermissionSuccess,
  onPermissionError,
  ...props
}, ref) {
  const videoRef = useRef(null); // Video html5 component reference
  const mediaRecorderRef = useRef(null);
  const [recording, setRecording] = useState(false); // Currently or not recording
  const [isVideoReady, setIsVideoReady] = useState(false); // Video is ready to be recorded
  const [timeElapsed, setTimeElapsed] = useState(0); // Time recording elapsed in seconds
  const [timer, setTimer] = useState(null); // setTimeout ID
  const [chunks, setChunks] = useState([]); // Chunks of the video
  const [replay, setReplay] = useState(false);
  const [mimeType, setMimeType] = useState(isSafari ? 'video/mp4' : 'video/webm');
  const [stream, setStream] = useState(null);
  const [countdown, setCountdown] = useState(null);

  const getMediaStream = async () => {
    try {
      const newStream = await navigator.mediaDevices.getUserMedia({
        video: { height: 360, width: 480 },
        audio: true,
      });

      setStream(newStream);
      onPermissionSuccess && onPermissionSuccess()
      return newStream;
    } catch (e) {
      onPermissionError && onPermissionError()
      return e;
    }
  };

  useEffect(() => {
    getMediaStream();
  }, []);

  const startRecording = () => {
    if (recording || !isVideoReady) return;
    getMediaStream().then((s) => {
      setChunks([]);
      let mediaRecorder;
      try {
        mediaRecorder = new MediaRecorder(
          s,
          {
            mimeType: mimeType,
            height: 360,
            width: 480,
            bitsPerSecond: 512000,
          }
        );
      } catch (e) {
        const nextMimeType = oppositeMimeTypes[mimeType];
        setMimeType(nextMimeType);
        mediaRecorder = new MediaRecorder(
          s,
          {
            mimeType: nextMimeType,
            height: 360,
            width: 480,
            bitsPerSecond: 512000,
          }
        );
      }
      mediaRecorderRef.current = mediaRecorder;
      mediaRecorder.start();
      mediaRecorder.addEventListener('dataavailable', function (e) {
        if (e.data && e.data.size > 0) {
          // Se podria caer si los chunks son mucho mas largos
          const newChunks = [e.data];
          setChunks(c => [...c, ...newChunks]);
        }
      });
      setTimer(setTimeout(() => {
        setTimeElapsed(timeElapsed + 1)
      }, 1000));
      onCountdown(0);
      onRecording && onRecording(true);
      setRecording(true);
    })
  }

  const startRecordingWithCountdown = (delay) => {
    setCountdown(delay);
    onCountdown(delay);
    if (delay > 0) {
      setTimeout(() => {
        startRecordingWithCountdown(delay - 1);
      }, 1000);
    } else {
      startRecording();
    }
  }

  const stopRecording = () => {
    if (!recording) return;
    setRecording(false);
    clearTimeout(timer);
    setTimer(null);
    setIsVideoReady(false);
    mediaRecorderRef.current.stop();
    const tracks = stream.getTracks();
    tracks.forEach(function (track) {
      track.stop();
    });
    videoRef.current.srcObject = null;
  }

  useEffect(() => {
    if (!recording && chunks.length) {
      onRecorderReady(false);
      onFinishRecording(...generateURL(chunks));
      demo && playRecordedVideo();
    }
  }, [recording, chunks.length]);
  
  // Generate URL to download video.
  const generateURL = (chunks) => {
    if (!chunks || !chunks.length) return;
    const blob = new Blob(chunks, { type: mimeType });
    const url = URL.createObjectURL(blob);
    return [url, blob, mimeType];
  }

  // Get video directly into a download.
  const download = () => {
    const url = generateURL(chunks);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.href = url;
    a.download = `test.${mimeType.split('/')[1]}`;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  const setUpVideo = async (forceGetStream) => {
    setReplay(false);
    setTimeElapsed(0);
    onRecorderReady && onRecorderReady(false);
    let s;
    if (!stream || forceGetStream) {
      s = await getMediaStream();
    } else {
      s = stream;
    }
    const video = videoRef.current;
    video.srcObject = s;
    // Play video in iOS Safari
    video.onloadedmetadata = () => {
      // Se cae por permisos en Safari
      const videoEl = document.getElementById('video');
      videoEl.play().catch((e) => {
        // Just in case, esto botaba safari con video
        // sin modo inline.
      });
      onRecorderReady && onRecorderReady(true);
      setIsVideoReady(true);
    };
  }

  const playRecordedVideo = () => {
    const blob = new Blob(chunks, { type: mimeType });
    const url = URL.createObjectURL(blob);
    setReplay(true);
    videoRef.current.srcObject = null;
    videoRef.current.src = url;
    videoRef.current.play();
  }

  const playVideo = () => {
    videoRef.current.play().then((a) => {console.log(a)}).catch((e) => {
    });
  }

  const reset = () => {
    setStream(null)
    videoRef.current.pause();
    setTimeElapsed(0);
    onRecorderReady && onRecorderReady(false);
    setUpVideo(true).then(() => {
      playVideo();
      onRecorderReady(true);
    });
  }

  useEffect(() => {
    if (!recording && timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    if (timeElapsed < time && timeElapsed >= 0 && recording) {
      setTimer(setTimeout(() => {
        setTimeElapsed(timeElapsed + 1)
      }, 1000));
    } else if (timeElapsed >= time && recording) {
      stopRecording();
    }
  }, [timeElapsed]);

  useEffect(() => {
    onRecordingProgress(timeElapsed)
  }, [timeElapsed]);

  useEffect(() => {
    // Set reference current value
    if (ref) {
      ref.current = {
        startRecording,
        startRecordingWithCountdown,
        stopRecording,
        download,
        playRecordedVideo,
        playVideo,
        setUpVideo,
        reset,
      }
    }
  }, [
    ref,
    startRecording,
    startRecordingWithCountdown,
    stopRecording,
    download,
    playRecordedVideo,
    playVideo,
    setUpVideo,
    reset,
  ]);

  return (
    <div style={{ width:'100%', height: props.height ? props.height : '100%' }}>
      <div style={{ height: '100%', width: '100%', backgroundColor: 'black' }}>
        <video style={{ zIndex:1, objectFit: 'contain' }} controls={replay} muted={!replay} ref={videoRef} id="video" width="100%" height="100%" playsInline /> 
        {countdown > 0 ? (
          <div
            style={{
              width: 75,
              height: 75,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 2,
              color: 'white',
              fontSize: '65px',
              fontWeight: 'bold',
              border: '5px solid white',
              borderRadius: '50%',
              animation: 'pulse 1s infinite',
            }}
          >
            {countdown}
          </div>
        ) : null}
      </div>
      {showButtons ? (
        <div>
          <Button onClick={stopRecording} disabled={!recording}>Stop Recording</Button>
          <Button onClick={startRecording} disabled={!isVideoReady || recording}>Start Recording</Button>
          <Button onClick={() => download(chunks)} disabled={!chunks.length}>Download recorded video</Button>
          <Button onClick={setUpVideo} disabled={recording}>Set up video</Button>
          <Button onClick={playRecordedVideo} disabled={!chunks.length}>Play recorded video</Button>
          <Button onClick={playVideo}>PLAY</Button>
          <Button onClick={getMediaStream}>Get media stream</Button>
        </div> 
       ) : null}
    </div>
  );
}

export default forwardRef(VideoRecorder);

import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { Modal } from 'antd';
import {
  isIOS,
  isChrome,
  isSafari
} from "react-device-detect";
import './App.css';
import AppHeader from './components/header/header';
import ProcessDetail from './containers/process-detail';
import Interview from './containers/interview';
import RecorderTest from './components/video-test';

function App() {

  useEffect(() => {
    console.log(isIOS, isSafari);
    if (isIOS && !isSafari) {
      Modal.error({
        centered: true,
        title: 'Explorador no soportado',
        content: 'Lo sentimos, actualmente este explorador no está soportado en iOS para nuestra aplicación. Por favor entra usando Safari.',
      });
    } else if (!isIOS && !isChrome) {
      Modal.error({
        centered: true,
        title: 'Explorador no recomendado',
        content: 'Detectamos que no estás usando Google Chrome. Para una mejor experiencia y evitar posibles problemas, te recomendamos realizar la videoentrevista en Google Chrome.',
      });
    }
  })

  return (
    <div className="App">
    <AppHeader />
    <Router>
      <div>
        <Switch>
          <Route exact path="/">
            <ProcessDetail />
          </Route>
          <Route path="/processes/:id/interview" component={Interview} />
          <Route path="/processes/:id/" component={ProcessDetail} />
          <Route path="/test" component={RecorderTest} />
        </Switch>
      </div>
    </Router>
    </div>
  );
}

export default App;

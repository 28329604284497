import React, { Component } from 'react';
import { Progress, Card, Steps, Popover } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';

import Recorder from '../../components/video-recorder';
import { getInterviewQuestions,
  validateCandidateInterview,
  createAnswer,
  notifyInterviewEnd } from '../../services/interviews';
import { uploadVideo } from '../../services/video';
import checkImage from '../../assets/check.png'
import monoGenoma from '../../assets/mono-genoma.png'
import './styles.scss'

const { Step } = Steps;

const customDot = (dot, { status, index }) => (
  <Popover
    content={ 
      <strong>
        {status === 'finish' ? <span><CheckCircleFilled /> Completado</span> : status === 'process' ? 'En proceso' : 'Incompleto'}
      </strong>
    }
  >
    {dot}
  </Popover>
);

class Interview extends Component {
  state = { 
    loading: false,
    currentQuestion: 0,
    questions: [],
    candidateName: "",
    ready: false
  }

  async componentDidMount() {
    this.setState({ loading: true })
    try {
      this.loading = true;
      const id = this.props.match.params["id"]
      // const id = this.route.snapshot.paramMap.get('id');
      const aux = await validateCandidateInterview(id);
      // Guardar toda la información de la entrevista que se está consultando y
      // del candidato que va a responder la entrevista
      this.setState({
        processId: id,
        interviewId: aux.data.interview.id,
        interviewInfo: aux.data.interview
      })
      const { candidate, interview, expired } = aux.data;
      this.setState({ expired });
      if (!interview || expired) { throw new Error('No hay registro de entrevista o la videoentrevista expiró'); }
      // Sacar la información del candidato y enviarlo al momento de consultar por
      // las preguntas de la entrevista (para sacar cuáles son las que respondió
      // el usuario actual y partir desde ahí).
      // Por ahora se está enviando así no más el id del usuario, pero a futuro
      // debiese haber alguna validación o mejorar la forma de sacar la info
      const candidateId = candidate.id;
      const candidateMail = candidate.mail;
      const candidateName = candidate.name;
      this.setState({
        candidateId, candidateMail, candidateName
      })
      const questions = (await getInterviewQuestions(interview.id, candidateId)).data
      this.setState({ questions });
      // // Recorrer el arreglo con todas las preguntas del proceso e ir viendo
      // // hasta cuál no respondió el usuario. En caso que venga alguna respuesta
      // // del usuario
      this.setState({ ready: false })
      let currentQuestion = 0;
      for (const question of questions) {
        if (question.answers.length) { currentQuestion += 1 }
        else { break; };
      }
      this.setState({ currentQuestion, ready: true });

    } catch (err) {
      console.error('Ocurrió un error', err);

    } finally {
      this.setState({ loading: false });
    }
  }

  handleVideoRecorded = async (videoId) => {
    // videoLoading = true;
    const { interviewId, candidateMail } = this.state;
    this.setState({ loading: true });
    let newCurrentQuestion;
    try {
      const data = {
        candidate: { id: this.state.candidateId },
        video: { id: videoId },
        question: { id: this.state.questions[this.state.currentQuestion].id }
      };
    
      await createAnswer(data);
      // sumar 1 del video que acaba de responder
      let { currentQuestion } = this.state;
      // para ir a la posición de la pregunta que no tiene respondida y saltar la(s) que ya respondió
      const questionToSkip = currentQuestion;
      this.setState({ ready: false })
      newCurrentQuestion = 0;
      this.state.questions.forEach((question, i) => {
        if (questionToSkip >= i || question.answers.length) { 
          newCurrentQuestion += 1;
        } 
      });
      this.setState({ currentQuestion: newCurrentQuestion });
      setTimeout(() => { this.setState({ ready: true }) }, 2000); // delay making the recorder appear after the question is set
    } catch (err) {
      console.error('Ocurrió un error', err);

    } finally {
      if (this.state.questions.length <= newCurrentQuestion) {
        try {
          await notifyInterviewEnd({
            interview_id: interviewId,
            personal_user_email: candidateMail
          })
        } catch {
          console.log('Error en notificación')
        }
      }
      this.setState({ loading: false })
    }
  }

  uploadVideo = async (data) => {
    return await uploadVideo(data);
  }


  render() { 
    const { loading, questions, currentQuestion, expired } = this.state;
    return ( 
      <div className='pd'>
      {loading ? 
        <Progress percent={100} status="active" showInfo={false} />
      : null }

      {!loading && questions.length > currentQuestion ? // && !hideapp
      <div className="int">
        <Card
          className='mat-card'
          title={
              <article className="int__title-and-description">
                <h1 style={{whiteSpace: 'normal'}}>{ questions[currentQuestion].text || '' }</h1>
                <h4>Tienes <strong>{questions[currentQuestion].time || 10} segundos</strong> para contestar</h4>
              </article>            
          }
        >
            <div className='mat-card-content'>
              <div className="mat-tab-content">
                <section className="video-test">
                  <div className="row">
                    
                    <article className="video-test__video-section">
                      {(this.state.ready && questions[currentQuestion] && questions[currentQuestion].time && questions[currentQuestion].time < 301) ? 
                        <Recorder
                          className="video__wrapper"
                          time={questions[currentQuestion].time || 10}
                          handleVideoRecorded={this.handleVideoRecorded}
                          uploadVideo={this.uploadVideo}
                          questionId={questions[currentQuestion].id}
                          candidateId={this.state.candidateId}
                          interviewId={this.state.interviewId}
                          trials={1}
                        />
                      : null}
                    </article>
                  </div>
                </section>
              </div>
            </div>

            <div className='int__steps'>
              <Steps
                size="small"
                current={currentQuestion}
                progressDot={customDot}
              >
                {questions.map((q, index) => {
                  return(
                    <Step
                      key={index}
                      title={<div className='q-text'>Pregunta {index + 1}</div>}
                      // subTitle={`${q.time || 10} segundos`}
                      status={currentQuestion > index ? "finish" : currentQuestion === index ? "process" : "wait"}
                    />    
                  )
                })}
              </Steps>
            </div>
        </Card>
      </div>
      : null }

      {!loading && !expired && questions?.length === currentQuestion ? 
        <div className="interview-finished">
          <Card className='card' title={
            <div>
              <img width='60' src={checkImage} alt="Genoma - Video entrevista finalizada" />
              <h1>Entrevista finalizada</h1>
            </div>
          }>
            <p>¡Has finalizado exitosamente la video entrevista!</p>
            {/* <p>La empresa a la cual postulaste te contactará dentro de las próximas semanas
              para manternerte en línea con los avances del proceso.</p> */}
          </Card>
        </div>
      : null}
      {/* VINT expirada */}
      {!loading && expired ? 
        <div className="unknown-interview">
            <Card className='card' title={
              <div>
                <img src={monoGenoma} alt='genomin' />
                <h1>Videoentrevista expirada</h1>
              </div>
            }>
              <p>El proceso ya no está aceptando más videoentrevistas. Gracias por participar.</p>
            </Card>
        </div>
          : null}
    </div>
     );
  }
}
 
export default Interview;

import React from 'react';
import { Layout  } from 'antd';
// import logo from './logo.svg';
import 'antd/dist/antd.css';
import "./header.scss";
import logo from '../../assets/LogoGenoma.png'

const { Header } = Layout;

export default function AppHeader() {
  return (
    <Header className='header'>
      <div className='mat-toolbar-row'>
        <span className="logo">
          <img src={logo} alt='' />
        </span>
      </div>
    </Header>
  )  
};

import React from 'react';
import Recorder from '../video-recorder/index';
import VideoRecorder from '../video-recorder/videorecorder';

import './styles.scss';

const RecorderTest = () => {
  return ( 
    <div className="test-phase">
      <h1>Prueba tu audio y video</h1>
      <article className="video-test__video-section">
        <Recorder 
          time={10}
          className='video-js'
          demo={true} 
          questionId={'questionid-test'}
          candidateId={'candidateid-test'}
          interviewId={'interviewid-test'}
          trials={3}
        />
      </article>
    </div>
   );
}
 
export default RecorderTest;

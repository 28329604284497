import environment from '../environments';
import axios from 'axios';

const getHeaders = () => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      'Authorization': `Token ${token}`
    }
  }
}
// data -> { url: string }
const uploadVideo = async (data) => {
  return await axios
    .post(`${ environment.apiUrl }/videos`, data, getHeaders());
}

export {
  uploadVideo
}
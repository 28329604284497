import environment from '../environments';
import axios from 'axios';

const getHeaders = () => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      'Authorization': `Token ${token}`
    }
  }
}
/**
 * Función para recibir todas las preguntas de una entrevista en específico.
 * Lo que hace es GET al endpoint para recibir esa información y retorna
 * una promesa con la respuesta de la API
 *
 * @param interviewId: id de la entrevista que se está consultando
 */
const getInterviewQuestions = async (interviewId, candidateId) => {
  return await axios
    .get(`${ environment.apiUrl }/interviews/${ interviewId }/questions?userId=${ candidateId }`, getHeaders());
}

/**
 * Función para recibir la información básica de una entrevista en específico.
 * Lo que hace es GET al endpoint y retornar una promesa con la respuesta
 * de la API
 *
 * @param interviewId: id de la entrevista a consultar
 */
const getInterviewInfo = async (interviewId) => {
  return await axios
    .get(`${ environment.apiUrl }/interviews/${ interviewId }`, getHeaders())
}

/**
 * Función para verificar y retornar toda la información sobre el candidato
 * y la entrevista en específico que se está consultando. Retorna una promesa
 * con la respuesta de la API y toda la información del candidado y entrevista.
 *
 * @param urlId: identificador del hash que relacion la entrevista con el candidato
 */
const validateCandidateInterview = async (urlId) => {
  return await axios
    .get(`${ environment.apiUrl }/interviews/candidates/${ urlId }`, getHeaders())
}

/**
 * Falta documentar función
 * @param data: {
 *  candidate: { id: this.candidateId },
 *  video: { id: evt.id },
 *  question: { id: this.questions[this.currentQuestion].id }
 * }
 */
const createAnswer = async (data) => {
  return await axios
    .post(`${ environment.apiUrl }/answers`, data, getHeaders())
}
/**
 * Función para avisar a genoma que terminó de responder la video entrevista
 * @param id: id de video entrevista
 * @param email: email del candidato
 */
const notifyInterviewEnd = async (data) => {
  axios.patch(`https://api.staging.genoma.work/api/v1/gamesandtests/6dnINzIopLcHF6jlJ47Z/`, data, getHeaders())
    .catch(() => {})
  return await axios
   .patch(`${environment.backendUrl}/api/v1/gamesandtests/6dnINzIopLcHF6jlJ47Z/`, data, getHeaders())
}

export { 
  getInterviewQuestions,
  getInterviewInfo,
  validateCandidateInterview,
  createAnswer,
  notifyInterviewEnd
 }
